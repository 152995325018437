import React from "react";
import Dropdown from "../Dropdown/DropDown";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {useData} from '../DataContext/DataContext'
import CustomButton from "../Button/Button";
export default function Nav2() {
  const navigate=useNavigate()
  const{setcheakpdfflag}=useData()
const  handle=()=>{
  setcheakpdfflag(false);
   alert("Are you sure you want to exit?")
  navigate("/")
  }
const handleButtonClick = () => {
  alert("Create Resume")
  setcheakpdfflag(false);

}
  return (
    <>
      <div className="container-fluid p-0  ">
        <div
          className="row  border-bottom "
          style={{ backgroundColor: "#0F8B8D" }}
        >
       
          <div className="col-6 p-2">
         
          {/* <FaArrowAltCircleLeft size={30} style={{backgroundColor:"#0f8b8d"}}  className="text-white"  onClick={()=>{handle()}}/> */}

            <img
              src="./images/seekr_logo.png"
              alt="resume image"
              className="img2 ps-4"
         
            />
       
         
          </div>
          <div className="col-6 d-flex justify-content-end p-2 m-0 ">
            {/* <img src="./images/btnupl.png" alt="resume image" /> */}
    
            <CustomButton bgColor="white" textColor="black" onClick={handleButtonClick}>
                   Re-Upload
      </CustomButton>
          
          </div>
        </div>
      </div>
      {/* <Button content="create resume"/> */}
      {/* <Dropdown /> */}
    </>
  )}
// export  {Nav2}



// export { Button}
