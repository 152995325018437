import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Ensure workerSrc is set, so pdfjs can load worker files from the correct path
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PdfEditor = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
const [pdfBytes,setPdfBytes]=useState();
  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <div>
      <Document
        file={pdfBytes} // Set this to the PDF bytes once loaded
        onLoadSuccess={handleLoadSuccess}
      >
        {[...Array(numPages)].map((_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
      <div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
          Previous
        </button>
        <button disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>
          Next
        </button>
      </div>
      <input type='file' onChange={(e) => setPdfBytes(e.target.files[0])} />
    </div>
  );
};

export default PdfEditor;
