import React from 'react'

function ScoreCard() {
  return (
   <>
     <div className="card shadow shadow border-0">
                    <div className="card-header">
                      <span
                        id=""
                        style={{
                          color: "#0F8B8D",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        Your Resume Score 75 out of 100
                      </span>
                      <hr />
                      <div>
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                         This is a decent start, but there's clear room for improvement on your resume. It scored low on some key criteria hiring managers and resume screening software look for, but they can be easily improved. Let's dive into what we checked your resume for, and how you can improve your score by 30+ points.
                        </span>
                      </div>
                    </div>
                  </div>
   </>
  )
}

export default ScoreCard