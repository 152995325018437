import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { useData } from "../../DataContext/DataContext";
import { ImOpera } from 'react-icons/im';
import './weak.css'
function ResumeWeakness() {
  const { Rgrammer } = useData();
  const {imageerror,apidata}=useData();
  const [isOpen, setIsOpen] = useState(false);

  const toggleHeight = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="card mt-5 shadow border-0 shadow">
        <div className="card-header">
          <span
            style={{
              color: "red",
              fontFamily: "sans-serif",
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            Resume Weaknesses
          </span>
          <br />
          <span
            id="3"
            style={{
              fontFamily: "sans-serif",
              fontWeight: "400",
              fontSize: "12px",
            }}
          >
            These points are weak and you need to improve
          </span>
          <hr />
{/*         
          <div className="row " style={{ marginBottom: isOpen ? "15px" : "10px" }}>
            <button onClick={toggleHeight} className="d-flex justify-content-between align-items-center border-0 text-danger">
              Grammatical mistakes
               {isOpen ? <span className="text-danger">&#x25B2;</span> : <span className="text-danger">&#x25BC;</span>}

            </button>

          {isOpen  &&   <div className='card'>
              <div className="card-body d-flex flex-wrap">
                {Rgrammer && Rgrammer.map((item, index) => (
                  <div className='col-4' key={index}>
                    <div className='btn btn-light rounded-3 mb-2 p-1' style={{ backgroundColor: "#FFD6D7" }}>
                      <MdCancel size={20} className="text-danger" />
                      <span className="ms-1 pe-2 text-danger" style={{ fontFamily: "sans-serif", fontWeight: "500", fontSize: "13px" }}>{item.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          </div> */}



         {imageerror && <div className='btn btn-light rounded-3 mb-2 p-1 bg1'>
                      <MdCancel size={20} className="text-danger" />
                      <span className="ms-1 pe-2 text-danger" style={{ fontFamily: "sans-serif", fontWeight: "500", fontSize: "16px"}}>{imageerror}</span>
                    </div>}

                 
<br/>
                {Rgrammer &&    <div className='btn btn-light rounded-3 mb-2 p-1 bg1'>
                      <MdCancel size={20} className="text-danger" />
                      <span className="ms-1 pe-2 text-danger" style={{ fontFamily: "sans-serif", fontWeight: "500", fontSize: "16px"}}>Grammatical mistakes</span>
                    </div>}
        </div>
      </div>
    </>
  );
}

export default ResumeWeakness;
