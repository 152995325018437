import React, { useState, useEffect } from "react";
import { BarLoader } from "react-spinners";
import "./spinner.css";
import { useNavigate } from "react-router-dom";
import { useData } from "../DataContext/DataContext";

function Spinner() {
  const{apidata,apistatus}=useData()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [load2, setload2] = useState(false);
  // setTimeout(() => {
    if(200===200){
      setTimeout(() => {
      setload2(true);
      }, 4000);
    }
    else{
     
      navigate("/")
      //  alert("Something went wrong in Api")
    
    }
   
  // }, 4000);
  if (load2) {
    navigate("/ViewPage2");
  }
  const texts = [
    "Analysing your resume....",
    "Identifying....",
    "Parsing....",
    "Almost there....",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState(texts[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentText(texts[currentIndex]);
  }, [currentIndex]);
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center backimg">
      <div style={{ height: "auto", width: "100%" }} className="text-center">
        <span className="arraydata text-light ms-5">{currentText}</span>
        <div className="bar">
          <BarLoader
            color={"#0f8b8d"}
            loading={loading}
            height={10}
            width={400}
            className="rounded-5"
          />
        </div>
      </div>
    </div>
  );
}

export default Spinner;
