import { useState } from "react";
import { Document, Page } from "react-pdf";
import { useData } from "../DataContext/DataContext";
import "./pdfview.css";
function Pdfviwer() {
  const { pdfile } = useData();
  // const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(pdfile);
  function onDocumentLoadSuccess({ numPages }) {
    // setNumPages(numPages);
  }

  return (
    <div className="row  text-center" style={{ height: "90vh" }}>
      <div
        className=" col-sm-12 overflow-auto "
        style={{ height: "calc(100vh - 60px)" }}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdf "
          width={1000}
        >
          <Page
            width={1000}
            // height={1000}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </div>
  );
}
export default Pdfviwer;
