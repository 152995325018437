import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios for API calls
import { pdfjs } from "react-pdf";
import { useData } from "../DataContext/DataContext";
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Spinner from "../LoadingSpinner/Spinner";
import ViewPage2 from "../ResumeView2 page/ViewPage2";
import "./home.css";
import AOS from "aos";
import "aos/dist/aos.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function Home() {
  // const navigate = useNavigate();
  const {
    pdfile,
    cheakpdfflag,
    updateData,
    setResumeName,
    saveddataIncontext,
    setRskill,
    setRgrammer,
  } = useData("");
  const [loading, setLoading] = useState(false);

  const handlefile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const fileType = file.type;
    if (fileType === "application/pdf") {
      updateData(file);
    } else {
      alert("Please choose a PDF file.");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    updateData(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (pdfile) {
      processPDFFile();
      apiCalledforGrammer();
      apiCalledforskill();

      setLoading(true);
    }
  }, [pdfile]);
  let response;

  // API call for image,
  const processPDFFile = async () => {
    try {
      const formData = new FormData();
      formData.append("file", pdfile);

      const uploadResponse = await axios.post(
        "http://192.168.29.123:5000/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("xx:", uploadResponse.data.prediction);

      console.log("xxyy:", uploadResponse.data.error);
      response = uploadResponse.status;
      saveddataIncontext(
        uploadResponse.data.prediction,
        uploadResponse.data.error
      );
    } catch (error) {
      console.error("Error in First API1111:", error);
    }

    /// for name api
    try {
      const formData = new FormData();
      formData.append("file", pdfile);

      const Nameres = await axios.post(
        "http://192.168.29.123:5000/name",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("name", Nameres.data);
      setResumeName(Nameres.data.name);
    } catch (error) {
      console.log(error);
    }
  };
  // Apic call for SKill sugggestion
  const apiCalledforskill = async () => {
    try {
      const formData = new FormData();
      formData.append("file", pdfile);
      const skillres = await axios.post(
        "http://192.168.29.123:5000/skills",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("skillres12121",skillres.data.recommended_skills);

      setRskill(skillres.data.recommended_skills);
    } catch (error) {
      console.error("Error in skill api:", error);
    }
  };

  // api call for Grammer sugggestion
  const apiCalledforGrammer = async () => {
    try {
      const formData = new FormData();
      formData.append("file", pdfile);
      const grammerRes = await axios.post(
        "http://192.168.29.123:5000/grammar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRgrammer(grammerRes.data.errors);
      console.log("grammerRes", grammerRes);
    } catch (error) {
      console.error("Error in grammer api:", error);
    }
  };

  setTimeout(() => {
    setLoading(false);
  }, 4000);

  // for animation effect aos

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once
      easing: "ease-in-out", // Easing function for the animation
    });
    AOS.refresh();
  }, [pdfile]); // Trigger when `pdfile` changes

  return (
    <>
      {loading ? (
        <Spinner />
      ) : cheakpdfflag ? (
        <ViewPage2 />
      ) : (
        <div className=" home1 ">
          <div className="home2   container-fluid m-0 ">
            <Navbar />

            <div className="row here m-0 ">
              <div className=" calm1 col-md-6 p-0 d-block justify-content-center align-items-center ">
                <div className="card1 card border-0 bg-transparent ms-5  ">
                  <div className="card-body">
                    <div className="card-title title ">
                      <span className=" head1 fs-1 text-light">
                        RESUME GRADER
                      </span>
                      <br />
                      <span className="fs-5 subhead1 text-light ">
                        <i className="ita">
                          Let our AI refine you resume for maximum <br />
                          important and caareer success
                        </i>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="picker d-flex justify-content-center align-items-center"
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={() => document.querySelector(".input-file").click()}
                >
                  <div>
                    <img
                      src="./images/Upload icon.png"
                      alt="resume image"
                      className="imguploadicon"
                    />
                    <br />
                    <span className="filetext d-flex justify-content-center align-items-center text-light">
                      Drag and Drop here Or &nbsp;
                      <span
                        style={{
                          textDecoration: "underline",
                          fontWeight: "700",
                        }}
                      >
                        Choose file
                      </span>
                    </span>
                    <form action="">
                      <input
                        type="file"
                        accept="pdf/*"
                        className="input-file"
                        hidden
                        onChange={handlefile}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className=" calm2 col-md-6 p-0  ">
                {/* <img
                                        src="./images/resumeimage.png"
                                        alt="resume image"
                                        className="img-responsive img1 "
                                        style={{ height: "100%", width: "100%" }}
                                      /> */}
                {/* <img
                                        className="gif-animation"
                                        src="./images/Ani4.gif"
                                        alt="Animated GIF"
                                        style={{ height: "100%", width: "100%" }}
                                      /> */}

                <div className="video-container ">
                  <video className="video-element " autoPlay muted loop>
                    <source src="./images/Ani1.webm" type="video/webm" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
