import React from 'react'
import { MdCancel } from "react-icons/md";
import { useData } from "../../DataContext/DataContext"; 
import { FaCheckCircle } from "react-icons/fa";
import './grammar.css'
import Dropdown from '../../Dropdown/DropDown'
function GrammerCard() {
  const {Rgrammer} = useData();
 
  return (
<>
      <div className="card mt-5 border-0 shadow">
                    <div className="card-header">
                      <div className="d-flex justify-content-between justify-content-center align-items-center ">
                        <span
                          id="GrammerCheak"
                          style={{
                          color: "#0F8B8D",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                        >
                          Grammar Evaluation
                        </span>
                     
               </div>
               <hr />
               <span className=''  style={{
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}><i>	&ldquo;Notice any error in your resume's grammar choice of words? Our AI tool can detect issues like  using worda's  instead of word's and recommended replacement ,ensuring a polished and Professional presentation. &rdquo;</i></span>
                          <br/>
                          <span className=' '  style={{
                          color: "#0F8B8D",
                          fontFamily: "sans-serif",
                          fontWeight: "300",
                          fontSize: "17px",
                        }}>Resume Grammatical errors & corrected words / suggestions:</span>
                           
             
             <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
    <div className='row mt-3 mx-3'>
        {Rgrammer && Rgrammer.map((item, index) => (
            <div key={index}>
                {/* {console.log("Rgrammeryyyyy", item)} */}
                <Dropdown error={item.text} suggestion={item.suggestions} />
                <br />
            </div>
        ))}
    </div>
</div>

     
    
        
         </div>
        
                               

           </div>
                 
</>
  )
}

export default GrammerCard