import React, { useState } from "react";
import axios from "axios";
import Pdfviwer from "../home/Pdfviwer"; // corrected component name
import { useData } from "../DataContext/DataContext";
import { MdCancel } from "react-icons/md";
import {callApi} from '../ApiService/api'
// import ImageAnalysis from "../Image AnalysisCard/ImageAnalysis";
function Cheak() {
  const { pdfile } = useData();
  console.log("input file for API call in cheak", pdfile);
  const [responseFromFirstAPI, setResponseFromFirstAPI] = useState("");
  let apidata='Professional';
  let imageerror='unprofessional';
  const postData = async () => {
    console.log("API called..", pdfile);
    if (pdfile) {
      try {
        const formData = new FormData();
        formData.append("file", pdfile);

        const uploadResponse = await axios.post(
          "http://192.168.68.81:5000/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Response from upload:", uploadResponse.data);
        setResponseFromFirstAPI(uploadResponse.data);

        // Second API call
        if (uploadResponse.status===200) {
          try {
            const predictResponse = await axios.post(
              "http://192.168.68.81:5000/predict",
              // formData, // No need for FormData here
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("Response from predict:", predictResponse.data.message);
          } catch (error) {
            console.error("Error in Second API:", error);
          }
        }
      } catch (error) {
        console.error("Error in First API:", error);
      }
    }
  };


  const fetchData = async () => {
    try {
      const obj={
        name:"NAGESH",
        LAST:"kumar 123"
      }
      const result = await callApi('GET', '/users'); // Make a GET request
     console.log('12345',result)
    } catch (error) {
      // Handle error (e.g., show error message to user)
    }
  };

  // fetchData(); 


  return (
    <>
      <button onClick={fetchData}>API Call Now</button>
      <div
                  style={{ height: 'auto', width: '100%', backgroundColor: '#FFB000' }}
                  className=" rounded"
                >
                  <div
                    style={{ height: 'auto',backgroundColor:"#ffffd1" }}
                    className="ms-2  rounded  p-3 data">
                    

 include a professional photograph can make a significant difference and highlight your dedication to presenting yourself int the best light possible .   <span className="fw-bold">it 's a chance to demonstrate your professionalism and attention to detail whithin your resume.</span>
              
                  </div>
            
                </div> 
            
               
          

               {  apidata &&  <div className=" rounded-1  " style={{width:"8%",backgroundColor:"#FFD6D7"}}>
                <MdCancel size={20} className="text-danger  "/>
                <span className="ms-3">{apidata}</span>
                </div>
               }

          {/* <ImageAnalysis/> */}

      <Pdfviwer />
    </>
  );
}

export default Cheak;
