import React,{useState} from 'react'
import './sugges.css'

import { useData } from "../../DataContext/DataContext"; 
function Suggestion() {
  const { Rgrammer } = useData();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleHeight2 = () => {
    setIsOpen2(!isOpen2);
  }
    const toggleHeight = () => {
    setIsOpen(!isOpen);
  };
  return (
  <>
     <div className="card mt-5 shadow border-0">
                    <div className="card-header">
                      <span
                        id="1"
                        style={{
                          color: "#0F8B8D",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        Suggestions
                      </span>
                      <hr />
                      <div>
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >

                            Enhance your resume with tailored suggestions
                        </span>
                        <hr/>
              

                        <div className="row " style={{ marginBottom: isOpen ? "15px" : "10px" }}>
            <button onClick={toggleHeight} className="font1 d-flex justify-content-between align-items-center border-0 "
            style={{color:"#6D6D6D",fontSize:"20px"}}>
            Optimize keywords for ATS
               {isOpen ? <span className="bg2">&#x25B2;</span> : <span className="bg2">&#x25BC;</span>}

            </button>

          {isOpen  &&   <div className='card border-0'>
              <div className="card-body d-flex flex-wrap align-items-center">
                {Rgrammer && Rgrammer.map((item, index) => (
                  <div className='col-md-4 ps-4'  key={index}>
                    <div className='btn btn-light rounded-5 mb-2  px-4' style={{ backgroundColor : "#0f8b8d" }}>
                    
                      <span className="ms-1 pe-2 text-light" >{item.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          </div>


          <div className="row " style={{ marginBottom: isOpen2 ? "15px" : "10px" }}>
            <button onClick={toggleHeight2} className="font1 d-flex justify-content-between align-items-center border-0 "
              style={{color:"#6D6D6D",fontSize:"20px"}}>
          Include relevant certifications and training
               {isOpen2 ? <span className="bg2">&#x25B2;</span> : <span className="bg2">&#x25BC;</span>}

            </button>

          {isOpen2  &&   <div className='card border-0'>
              <div className="card-body d-flex flex-wrap align-items-center">
                {Rgrammer && Rgrammer.map((item, index) => (
                  <div className='col-md-4 ps-4'  key={index}>
                    <div className='btn btn-light rounded-5 mb-2  px-4' style={{ backgroundColor : "#0f8b8d" }}>
                    
                      <span className="ms-1 pe-2 text-light" >{item.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          </div>
                       
                      </div>
                    </div>
                  </div>
  </>
  )
}

export default Suggestion