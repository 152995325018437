import React from 'react'
import { useData } from "../../DataContext/DataContext";
import './objective.css'
function Objective() {
  return (
<>
<div className="card mt-5 border-0 shadow">
                    <div className="card-header">
                      <div className="d-flex justify-content-between justify-content-center align-items-center ">
                        <span
                          id="objective"
                          style={{
                            color: "#0F8B8D",
                            fontFamily: "sans-serif",
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                       Objective Prompts
                        </span>
                    
              
               
                      </div>
                      <span   style={{
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}>AI generated professional’s suggestions</span>
                      <hr />
                      <div>

                      </div>
                    <div className="row bg11 font1"  >
                        <div className="col-12 d-flex justify-content-between my-3">
                            <div><span className='py-1' style={{fontSize:"17px",width:"80%"}}>"Fostered collaboration between development teams and stakeholders to conceptualize and implement blockchain solutions that optimize business processes and enhance data integrity."</span></div>
                            <div><img src='./images/12.png' className='mt-3 ms-3 ' width={30} height={30}/></div>
                        </div>
                    </div>
<br/>
                    <div className="row bg11 font1"  >
                        <div className="col-12 d-flex justify-content-between my-3">
                            <div><span className='' style={{fontSize:"17px",width:"80%"}}>"Fostered collaboration between development teams and stakeholders to conceptualize and implement blockchain solutions that optimize business processes and enhance data integrity."</span></div>
                            <div><img src='./images/12.png' className='mt-3 ms-3 ' width={30} height={30}/></div>
                        </div>
                    </div>
                    </div>
                  </div>
</>
  )
}

export default Objective