import React from 'react';
import styled from 'styled-components';

// Styled button component
const StyledButton = styled.button`
  padding: 10px 24px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  ${'' /* border-radius: 4px; */}
  cursor: pointer;
  background-color: ${props => props.bgColor || '#007bff'};
  color: ${props => props.textColor || '#ffffff'};

  /* Media query for responsiveness */
  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

// CustomButton component
const CustomButton = ({ children, bgColor, textColor, onClick }) => {
  return (
    <StyledButton bgColor={bgColor} textColor={textColor} onClick={onClick} className='rounded-5'>
      {children}
    </StyledButton>
  );
};

export default CustomButton;
