// DataContext.js

import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [pdfile, setPdfile] = useState(null);
  const [apidata, setapiData] = useState(null);
  const [apistatus,setapistatus]=useState(null);
  const[imageerror,setimageError]=useState(null)
  const[cheakpdfflag,setcheakpdfflag]=useState(false)
  const [Rskill,setRskill]=useState(null);
  const [Rgrammer,setRgrammer]=useState(null);
    const[ResumeName,setResumeName]=useState(null);

// console.log('zz',apidata)
// console.log('z3',imageerror)

  const updateData = (newData) => {
    setPdfile(newData);
    setcheakpdfflag(true)
  };
//   const saveddataIncontext = (Data) => {
//     const { pridictdata, response } = Data;
//     setapiData(pridictdata);
//     setapistatus(response);
// };
const saveddataIncontext = (apidata,error) => {
  setapiData(apidata);

  setimageError(error)
}

  // console.log("resume pdf in context", pdfile);
  // console.log("apidata in context", imageerror);
  // console.log('api status in context',apistatus)
  return (
    <DataContext.Provider value={{ pdfile,apistatus,apidata,saveddataIncontext,Rskill,Rgrammer,setRskill, updateData,setcheakpdfflag,setRgrammer,cheakpdfflag, imageerror,ResumeName,setResumeName,setapiData}}>
      {children}
    </DataContext.Provider>
  );
}; 
