import React from 'react'
import './nav.css'
import CustomButton from '../Button/Button';
function Navbar() {
  const handleButtonClick = () => {
    alert("Create Resume")
  }
  return (
<>

         <div className="row d-flex justify-content-center align-items-center  pt-5 flex-wrap">
        <div className='nav1 rounded-5 d-flex flex-inline 14px'>
        <img src='./images/seekr_logo.png'  alt="resume image" className=' img2 ps-4' />
        {/* <button className='btn1 border-0 rounded-5'>Create Resume</button> */}
        <div className='my-2'>
        <CustomButton bgColor="#0f8b8d" textColor="#ffffff" onClick={handleButtonClick}>
          Create Resume
          </CustomButton>
          </div>
        </div>
      
         </div>
     
</>
  )
}

export default Navbar