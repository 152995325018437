import React, { useState } from "react";
// Import CSS file for styling
import "./drop.css";

function ToggleableComponent({error,suggestion}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleHeight = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className=" row "  
      style={{ marginBottom: isOpen ? "15px" : "0px" }}   
    >
      <button onClick={toggleHeight} className="d-flex justify-content-between align-items-center border-0 text-danger">
     
        {error}
        {isOpen ? <span className="text-danger">&#x25B2;</span> : <span className="text-danger">&#x25BC;</span>}
      </button>
      <div className={`toggleable-content ${isOpen ? "open pb-5" : ""}`}>
     
<div className="d-flex  justify-content-space-evenly align-items-center flex-wrap  p-2 " >
      {Array.isArray(suggestion) && suggestion.map((item, index) => (
    <React.Fragment key={index}>
    
        {/* {console.log("suggestionkk", item)} */}
        <button key={index} className="btn btn-success py-0 rounded-5 my-2" style={{backgroundColor:"#0F8B8D"}}>{item}</button>&nbsp; &nbsp;
    </React.Fragment>
))}

</div>

      
      </div>
    </div>
  );
}

export default ToggleableComponent;
