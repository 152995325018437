import axios from 'axios';

const api = axios.create({
  baseURL: '', // Set your API base URL here
  timeout: 10000, // Specify request timeout
  headers: {
    'Content-Type': 'application/json', // Set default content type
  },
});

// Utility function to make API calls
export const callApi = async (method, endpoint, data = null, headers = {}) => {
  try {
    const response = await api({
      method,
      url: endpoint,
      data,
      headers,
    });
    return response.data; // Return response data
  } catch (error) {
    // Handle API errors (e.g., network error, server error)
    console.error('API Errorxxxxx:', error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};
