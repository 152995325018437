import React, { useEffect, useState } from "react";
import "./viewpage.css";
import { useData } from "../DataContext/DataContext";
import { FaUser } from "react-icons/fa";
import Nav2 from "../Nav2second/Nav2";
import Semicircle from "../semiprogress/Semicircle";
import Pdfviwer from "../home/Pdfviwer";
import ImageAnalysis from "../CardComponent/Image AnalysisCard/ImageAnalysis";
import GrammerCard from "../CardComponent/Grammer/GrammerCard";
import Skillset from "../CardComponent/skill-suggestion Card/Skillset";
import Suggestion from "../CardComponent/Suggestion/Suggestion";
import ResumeSrength from "../CardComponent/resume strength/ResumeSrength";
import ResumeWeakness from "../CardComponent/resumeWeakness/ResumeWeakness";
import Objective from "../CardComponent/Objective/Objective";
import ScoreCard from "../CardComponent/ResumScor/ScoreCard";
import axios from "axios";
function ViewPage2() {
  const [data, setData] = useState("");
  const { pdfile } = useData("");

  useEffect(() => {
    const getData = async () => {
      try {
        const formData = new FormData();
        formData.append("file", pdfile);
        const resumeGrade = await axios.post(
          "http://192.168.29.123:5000//grade-resume",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setData(resumeGrade.data.ATS);

        // setData(grammerRes.data.errors);
        // console.log("grammerRes", grammerRes);
      } catch (error) {
        console.error("Error in grammer api:", error);
      }
    };

    if (pdfile) {
      getData();
    }
  }, [pdfile]);
  const data2 = {
    initial: {
      unit: "%",
      value: 100,
    },
    remaining: {
      unit: "%",
      value: data,
    },
    type: "Internet",
  };
  const { imageerror, ResumeName } = useData();
  console.log("resume data in ViewPage2 show", imageerror);
  const [score, setScore] = useState(58);
  const [ids, setIds] = useState(1);
  const scrollToDiv = (id) => {
    console.log(id);
    setIds(id);
  };

  return (
    <>
      <div className="container-fluid " style={{ height: "100vh" }}>
        <Nav2 />
        {/* <Button content="create resume"/> */}
        <div className="row shadow">
          <div className="col-md-2 p-0 heightall shadow">
            <div
              className=" shadow col-md-12 d-flex justify-content-center align-items-center "
              style={{ height: "174px", backgroundColor: "#0F8B8D" }}
            >
              <div className="">
                <Semicircle
                  min={0}
                  max={data2.initial.value}
                  value={data2.remaining.value}
                />
              </div>
            </div>

            <div className="col-md-12 ">
              <div
                style={{ height: "68vh" }}
                className="d-flex flex-column justify-content-between"
              >
                <div className="row m-0  ps-2" style={{ paddingTop: "10%" }}>
                  <div
                    className="picker2 col-md-12  rounded-5  p-3 kk"
                    // style={{ backgroundColor: "#0F8B8D" }}
                    style={
                      ids === "1"
                        ? { backgroundColor: "#0F8B8D", color: "white" }
                        : { backgroundColor: "" }
                    }
                  >
                    <div
                      className="d-flex justify-content-start align-items-center "
                      onClick={() => scrollToDiv("1")}
                    >
                      {/* <img src="./images/User.png" /> */}
                      <FaUser />
                      <a
                        href="#imageAnalysis"
                        className={
                          ids === "1"
                            ? "ps-2 fw-bold text-light "
                            : "ps-2 fw-bold"
                        }
                        // Pass the ID as a string
                      >
                        Profile Photo Analyser
                      </a>
                    </div>
                  </div>

                  <div
                    className="col-md-12  rounded-5  p-3 kk"
                    style={
                      ids === "2"
                        ? { backgroundColor: "#0F8B8D", color: "white" }
                        : { backgroundColor: "" }
                    }
                  >
                    <div
                      className="d-flex justify-content-start align-items-center "
                      onClick={() => scrollToDiv("2")}
                    >
                      <img
                        src="./images/sidebarFilei.png"
                        className="text-light"
                      />
                      <a
                        href="#GrammerCheak"
                        className={
                          ids == 2 ? "ps-2 fw-bold text-light" : "ps-2 fw-bold"
                        }
                      >
                        Grammatical checker
                      </a>
                    </div>
                  </div>

                  <div
                    className="col-md-12  rounded-5  p-3 kk "
                    style={
                      ids === "3"
                        ? { backgroundColor: "#0F8B8D", color: "white" }
                        : { backgroundColor: "" }
                    }
                  >
                    <div
                      className="d-flex justify-content-start align-items-center "
                      onClick={() => scrollToDiv("3")}
                    >
                      <img src="./images/Text.png" className="text-light" />
                      <a
                        href="#Skillset"
                        className={
                          ids == 3 ? "ps-2 fw-bold text-light" : "ps-2 fw-bold"
                        }
                        id="3"
                      >
                        skills set Suggestions{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row ps-4  ">
                  <div className="col-6   rounded-5 p-3 bg-dark  ">
                    <div className="d-flex justify-content-start align-items-center ">
                      <img src="./images/user2.png" className="text-light" />
                      <span className="ps-2 text-light fw-300">Profile</span>
                      <img src="./images/arrow.png" className="text-light" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="container heightall">
              <div className="col-md-12 ">
                <h1
                  className="pt-5 mb-5"
                  style={{
                    fontFamily: "sans-serif",
                    fontWeight: "550",
                    fontSize: "36px",
                  }}
                >
                  Hello, {ResumeName}
                </h1>
              </div>

              <div //Card Section
                className="container "
              >
                <div
                  style={{
                    paddingBottom: "20%",
                    height: "calc(100vh - 200px)",
                  }}
                  className="overflow-auto"
                >
                  <ScoreCard />
                  <Suggestion />
                  <Objective />
                  <ImageAnalysis />
                  <GrammerCard />
                  <Skillset />
                  <ResumeSrength />
                  <ResumeWeakness />
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 text-center contentdiv"
            style={{ height: "92vh" }}
          >
            {/* <img
                src="./images/depakresume.png"
                alt="resume image"
                className=""
                style={{ width: "100%" }}
              /> */}
            {/* <button onClick={postData}>Call APi</button> */}
            <div
              className="overflow-auto pdfshow"
              style={{ height: "calc(100vh - 69px)" }}
            >
              <Pdfviwer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPage2;
