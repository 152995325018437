import React from 'react'
import { useData } from "../../DataContext/DataContext";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
function ImageAnalysis() {
    const {imageerror,apidata}=useData();
  return (
   <>
      <div className="card mt-5 border-0 shadow">
                    <div className="card-header">
                      <div className="d-flex justify-content-between justify-content-center align-items-center ">
                        <span
                          id="imageAnalysis"
                          style={{
                            color: "#0F8B8D",
                            fontFamily: "sans-serif",
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          Image Analysis
                        </span>
                       <span> {  apidata &&  <div className=" rounded-1 px-2" style={{width:"auto",backgroundColor:"#CFDDE2"}}>
                       <FaCheckCircle size={15} className="me-1 mb-1" style={{color:"#0f8b8d"}}/>
                <span className="bg2" style={{color:"#0f8b8d"}}>{apidata}</span>
                </div>
               }
               {  imageerror &&  <div className=" rounded-1 px-2 " style={{width:"auto",backgroundColor:"#FFD6D7"}}>
                <MdCancel size={20} className="text-danger  m-1"/>
                <span className="text-danger">{imageerror}</span>
                </div>
               }
             
                </span>
              
               
                      </div>
                      <hr />
                      <div></div>
                      <div className="card-body border-0 m-0">
                      <div
                  style={{ height: 'auto', width: '100%', backgroundColor: '#FFB000' }}
                  className=" rounded"
                >
                  <div
                    style={{ height: 'auto',backgroundColor:"#ffffeb" ,  fontFamily: "sans-serif", fontWeight: "400",
                            fontSize: "14px",}}
                    className="ms-1  rounded  p-3 data">
 include a professional photograph can make a significant difference and highlight your dedication to presenting yourself int the best light possible .   <span className="fw-bold">it 's a chance to demonstrate your professionalism and attention to detail whithin your resume.</span>
              
                  </div>
                </div>
                      </div>
                    </div>
                  </div>
   </>
  )
}

export default ImageAnalysis