import React from 'react'
import { useData } from "../../DataContext/DataContext";
import { IoMdArrowRoundForward } from "react-icons/io";
import './skill.css'
function Skillset() {
 const {Rskill} = useData();

 
//  if (Rskill && Array.isArray(Rskill)) {
//   Rskill.map((item, index) => {
//       console.log('skill', item.recommended_skills);
//   });
// } else {
//   console.log("Rskill is either null or not an array.");
// }



  return (
<>

<div className="card mt-5 border-0 shadow lg">
                    <div className="card-header">
                      <div className="d-flex justify-content-between justify-content-center align-items-center ">
                        <span
                          id="Skillset"
                          style={{
                          color: "#0F8B8D",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                        >
                    Skill-set Suggestions
                        </span>
                     
               </div>
               <hr />
               <span className=''  style={{
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}><i>	&ldquo;Customizing your skills section can elevate your resume 's impact and catch the eye of the protectionemployer's.Here are some skills you can add to your resume &rdquo;</i></span>
             
             <div className='row mt-3'> 
          <div className="col-12   d-flex flex-wrap ">
                                  {Rskill && Rskill.map((item, index) => (
                                    <ul style={{listStyle:"none"}} className='ps-2'>
                                    <li>
                                    <div key={index} className=" rounded-1 " style={{width:"auto",backgroundColor:"#CFDDE2",paddingRight:"0px"}}>
                                      <IoMdArrowRoundForward size={15} className="  " style={{color:"#0f8b8d"}}/>
                                      <span className="px-2" style={{color:"#0f8b8d"}}>{item}</span>
                                      </div>
                                      </li>
                                      </ul>
                                  ))}
                           
                                    
          </div>
                                   
         
          </div>
          </div>
</div>
                     
               
</>
  )
}

export default Skillset