import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
import { useData } from "../../DataContext/DataContext";

function ResumeSrength() {
  const {apidata}=useData();
  return (
   <>
     <div className="card mt-5 shadow border-0 shadow">
                    <div className="card-header">
                      <span
                        id="3"
                        style={{
                          color: "#0F8B8D",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        Resume Strengths
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          fontSize: "12px",
                        }}
                      >
                        These points are the great in your resume
                      </span>
                      <hr />
                      <div>
                      <div className=' row d-flex jstify-content-flex-start'>
                     <div className="col-3 rounded-1 px-2 " style={{width:"auto",backgroundColor:"#CFDDE2"}}>
                       <FaCheckCircle size={15} className="me-1 mb-1" style={{color:"#0f8b8d"}}/>
                <span className="bg2 " style={{color:"#0f8b8d",fontSize:"18px"}}> Use of bullet points   </span>
                </div>

                <div className="col-3 rounded-1 px-2 ms-4 " style={{width:"auto",backgroundColor:"#CFDDE2"}}>
                       <FaCheckCircle size={15} className="me-1 mb-1" style={{color:"#0f8b8d"}}/>
                <span className="bg2" style={{color:"#0f8b8d",fontSize:"18px"}}>          Formatting is accurate  </span>
                </div>
               
                        <span style={{width:"50%"}} className='py-3'>{  apidata &&  <div className=" rounded-1 px-2" style={{width:"50%",backgroundColor:"#CFDDE2"}}>
                       <FaCheckCircle size={15} className="me-1 mb-1" style={{color:"#0f8b8d"}}/>
                <span className="bg2" style={{color:"#0f8b8d",fontSize:"18px"}}>{apidata}</span>
                </div>
               } </span>
                      </div> 
                      </div>
                    </div>
                  </div>
   </>
  )
}

export default ResumeSrength