
import ViewPage2 from "./components/ResumeView2 page/ViewPage2";
import Home from "./components/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Nav2 from "./components/Nav2second/Nav2";
import Spinner from "./components/LoadingSpinner/Spinner";
import Cheak from "./components/Apicall/Cheak";
import PDFTextEditor from "./components/PDFTextEditor/PDFTextEditor";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/Viewpdf" element={<ViewPage />} /> */}
          <Route path="/apitest" element={<Cheak />} />
          <Route path="/ViewPage2" element={<ViewPage2 />} />
          <Route path="/loadingspinner" element={<Spinner />} />
          <Route path="/nav" element={<Navbar />} />
          <Route path="/nav2" element={<Nav2 />} />
          <Route path="/editer" element={<PDFTextEditor />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
